var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"footer-hide":"","title":_vm.get(_vm.activeProblemInfo, 'title'),"width":800},on:{"close":function($event){_vm.activeProblemInfo = undefined}},model:{value:(_vm.problemInfoModal),callback:function ($$v) {_vm.problemInfoModal=$$v},expression:"problemInfoModal"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.get(_vm.activeProblemInfo, 'description'))}})]),(_vm.$apollo.queries.problems.loading)?_c('sb-loading'):_vm._e(),_vm._l((_vm.problemsByType),function(problems,type,index){return _c('div',{key:type,style:({
      position: 'relative',
      zIndex: Object.keys(_vm.problemsByType).length - index,
      ...(_vm.readonly &&
        !_vm.getToggledProblemEntriesByCategory(type).length && {
          opacity: 0.4,
        }),
    }),attrs:{"data-amount":problems.length}},[_c('sb-spacer',{attrs:{"height":"40"}}),_c('sb-list-head',{staticStyle:{"grid-template-columns":"40px auto 150px 30px 30%"}},[_c('sb-list-head-col',{staticStyle:{"grid-column":"1 / span 2"}},[_vm._v(" "+_vm._s(_vm.getProblemTypeLabel(type, true))+" ")]),_c('sb-list-head-col',{staticStyle:{"grid-column":"3 / span 2"}},[_vm._v(" Aantal fouten ")]),_c('sb-list-head-col',[_vm._v(" Technieken ")])],1),_vm._l((problems),function(problem,problemIndex){return [(
          !_vm.hideDisabled || !!_vm.get(_vm.value, _vm.getProblemEntryIndexById(problem.id))
        )?_c('sb-list-row',{key:problem.id,staticClass:"sb_problem-row",style:({
          zIndex: problems.length - problemIndex,
          gridTemplateColumns: _vm.readonly
            ? '50px auto 150px 30px 30%'
            : '50px 46px auto 150px 30px 30%',
        })},[_c('sb-list-row-col',{staticStyle:{"height":"100%","padding":"0"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.showProblemInfoModal(problem)}}},[_c('div',{staticClass:"info"},[_c('sb-icon',{attrs:{"icon-id":"icon_info"}})],1)]),(!_vm.readonly)?_c('sb-list-row-col',[_c('checkbox',{attrs:{"value":!!_vm.get(_vm.value, _vm.getProblemEntryIndexById(problem.id))},nativeOn:{"click":function($event){$event.stopPropagation();!_vm.readonly && _vm.toggleProblemEntry(problem.id)}}})],1):_vm._e(),_c('sb-list-row-col',[_vm._v(" "+_vm._s(problem.title)+" ")]),_c('sb-list-row-col',{style:(_vm.readonly ? undefined : { paddingLeft: 0 })},[(!_vm.readonly)?_c('input-number',{staticStyle:{"width":"100%"},attrs:{"min":0,"step":1,"value":_vm.get(
                _vm.value,
                _vm.getProblemEntryIndexById(problem.id),
                'mistakeAmount',
              ) || 0},on:{"input":function($event){return _vm.handleMistakesAmountInput(problem.id, $event)}},nativeOn:{"click":function($event){$event.stopPropagation();}}}):[_vm._v(" "+_vm._s(_vm.get( _vm.value, _vm.getProblemEntryIndexById(problem.id), 'mistakeAmount', ) || 0)+" ")]],2),_c('sb-list-row-col'),_c('sb-list-row-col',{style:(_vm.readonly ? undefined : { paddingLeft: 0 })},[_c('sb-problems-input-techniques',{attrs:{"items":_vm.sortLinkedTechniques(problem.linkedTechniques)}})],1)],1):_vm._e()]})],2)}),_c('sb-spacer',{attrs:{"height":"140"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }